<template>
    <div class="main-container">
        <Header />   
        <div class="secoes_site">     
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>{{titulosecao}}</h1>
                                <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-grid-area section-space--inner--50">
            <div class="container">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 posts-list">
                        <div class="single-post">
                  <div class="feature-img">
                      <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + galerias.capa" class="img-fluid img-thumbnail" :alt="galerias.titulo">
                  </div>
                   
                    
               </div>
                       
                    </div>    
                    <div class="col-lg-8">
                        <h2>{{galerias.titulo}}</h2>
                        <p>{{galerias.resumo}}</p>
                      <div class="thumb-example">
                        <!-- swiper1 -->
                        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                        <swiper-slide v-for="foto in fotos" :key="foto.id">
                            <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + foto.arquivo" class="img-fluid">
                        </swiper-slide>
                        
                        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                        </swiper>
                        <!-- swiper2 Thumbs -->
                        <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                        <swiper-slide v-for="foto in fotos" :key="foto.id">
                            <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + foto.arquivo" class="img-fluid">
                        </swiper-slide>
                        </swiper>
                    </div>
                    </div>            
                </div>
            </div>
        </div>       
        <Footer />
        <!-- <OffCanvasMobileMenu /> -->
        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Footer from '../components/Footer';
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
    import 'swiper/css/swiper.css';
   // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    import axios from 'axios'
    export default {
        components: {
            Header,
            Swiper,
            SwiperSlide,
            Footer,

            //OffCanvasMobileMenu
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                swiperOptionTop: {
                    loop: true,
                    loopedSlides: 5,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                swiperOptionThumbs: {
                loop: true,
                loopedSlides: 5, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                slideToClickedSlide: true
                },
                titulosecao: "",
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: this.$route.params.pag,
                        active: true
                    }
                ],
                galerias: [],
                fotos: []
                
               
            }
        },
        mounted() {
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper
            const swiperThumbs = this.$refs.swiperThumbs.$swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
        })
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - Galeria`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
        var id = this.$route.params.id;  
        var slug = this.$route.params.slug;
        
        axios.get(`https://sistema.gouarts.com/api/galeria?id=`+id+`/?cliente=vitoriainspecoes`).then(res => {
            this.galerias = res.data.galeria;
            this.titulosecao = res.data.galeria.titulo;  
            this.fotos = res.data.fotos;         
                      
        }).catch(err => {
            console.log(err);
        })
}
</script>
<style lang="scss" scoped>
  .thumb-example {
    height: 480px;
    background-color: #000;
  }

  .swiper {
    .swiper-slide {
      background-size: cover;
      background-position: center;

    }

    &.gallery-top {
      height: 80%;
      width: 100%;
    }
    &.gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 0;
    }
    &.gallery-thumbs .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    &.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
  .thumb-example {
      margin-bottom: 50px;
  }
  .thumb-example img {
      width: 100%;
         
  }
  .feature-img img {
      width: 100%;
  }
</style>

